import axiosIns from "@/libs/axios";

export const uploadService = {
  uploadFile: async (file) => {
    try {
      const response = await axiosIns.post("/upload/file", file);
      return response.data;
    } catch (error) {
      throw error;
    }
  },
};
