<template>
  <b-tabs
    vertical
    content-class="col-12 col-md-9 mt-1 mt-md-0"
    pills
    nav-wrapper-class="col-md-3 col-12"
    nav-class="nav-left"
  >
    <!-- general tab -->
    <b-tab active>
      <!-- title -->
      <template #title>
        <feather-icon icon="UserIcon" size="18" class="mr-50" />
        <span class="font-weight-bold">{{ $t("general") }}</span>
      </template>

      <account-setting-general
        v-if="userData"
        :user-data-props="userData"
        :loading="loading"
      />
    </b-tab>
    <!--/ general tab -->

    <!-- change password tab -->
    <b-tab>
      <!-- title -->
      <template #title>
        <feather-icon icon="LockIcon" size="18" class="mr-50" />
        <span class="font-weight-bold">{{ $t("change_password") }}</span>
      </template>

      <account-setting-password />
    </b-tab>
    <!--/ change password tab -->
  </b-tabs>
</template>

<script>
import { BTabs, BTab } from "bootstrap-vue";
import AccountSettingGeneral from "./AccountSettingGeneral.vue";
import AccountSettingPassword from "./AccountSettingPassword.vue";
import { ref } from "@vue/composition-api";
import { adminService } from "@/services/admin";
import { useToast } from "vue-toastification/composition";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { decodeToken } from "@/@core/utils/decodeJwt";

export default {
  components: {
    BTabs,
    BTab,
    AccountSettingGeneral,
    AccountSettingPassword,
  },
  setup() {
    const toast = useToast();
    const userData = ref(null);
    const id = decodeToken().id;
    const loading = ref(false);

    adminService
      .getData(id)
      .then((response) => {
        loading.value = false;
        if (response.status) {
          userData.value = response.data;
        }
      })
      .catch((error) => {
        loading.value = false;
        toast({
          component: ToastificationContent,
          props: {
            title: "Error fetching data",
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
      });

    return {
      userData,
      loading,
    };
  },
};
</script>
