import axiosIns from "@/libs/axios";

export const authService = {
  companyLogin: async (data) => {
    try {
      const response = await axiosIns.post("/backoffice/auth/login", data);
      return response.data;
    } catch (error) {
      throw error;
    }
  },
  changePassword: async (password) => {
    try {
      const response = await axiosIns.put(
        "/backoffice/auth/change-password",
        password
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  },
};
