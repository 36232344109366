import axiosIns from "@/libs/axios";

export const adminService = {
  getData: async (id) => {
    try {
      const response = await axiosIns.get(`/backoffice/admin/${id}`);
      return response.data;
    } catch (error) {
      throw error;
    }
  },
  updateData: async (data, id) => {
    try {
      const response = await axiosIns.put(`/backoffice/admin/${id}`, data);
      return response.data;
    } catch (error) {
      throw error;
    }
  },
  getHomeStatic: async () => {
    try {
      const response = await axiosIns.get(`/backoffice/admin/get/home-static`);
      return response.data;
    } catch (error) {
      throw error;
    }
  },
};
