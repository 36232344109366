import dayjs from "dayjs";

//สถานะอนุมัติของแอดมิน
export const resolveEvaluationStatus = (status) => {
  if (status === "empty") return "secondary";
  if (status === "pending") return "warning";
  if (status === "normal") return "success";
  if (status === "abnormal") return "danger";
  return "primary";
};

export const resolveEvaluationText = (status) => {
  if (status === "empty") return "ยังไม่ประเมิน";
  if (status === "pending") return "รอประเมิน";
  if (status === "normal") return "ประเมินแล้ว";
  if (status === "abnormal") return "อันตราย";
  return "primary";
};

export const resolveApproveText = (status) => {
  if (status === "empty") return "รออนุมัติ";
  if (status === "pending") return "เฝ้าระวัง";
  if (status === "normal") return "ผ่านการประเมิน";
  if (status === "abnormal") return "ไม่ผ่านการประเมิน";
  return "ไม่ต้องอนุมัติ";
};

export const mappingEvaluationStatusText = (status) => {
  if (status === "empty") return "สถานะยังไม่ประเมิน";
  if (status === "pending") return "สถานะรอประเมิน";
  if (status === "normal") return "สถานะปกติ";
  if (status === "abnormal") return "สถานะอันตราย";
  return "ไม่รู้";
};

export const mappingStatusColor = (status) => {
  if (status === "normal") return "success";
  if (status === "risk") return "danger";
  if (status === "pending") return "warning";
  if (status === "not_complete") return "secondary";
  return "primary";
};

export const mappingStatusText = (status) => {
  if (status === "normal") return "สถานะปกติ";
  if (status === "risk") return "สถานะอันตราย";
  if (status === "pending") return "สถานะเฝ้าระวัง";
  if (status === "not_complete") return "สถานะเอกสารไม่ครบ";
  return "primary";
};
export const dateTimeToStr = (date) => {
  return dayjs(date).format("YYYY-MM");
};

export const constructUrlImage = (objImage) => {
  if (objImage) {
    return `${process.env.VUE_APP_API_URL}/files/${objImage.fileName}`;
  }

  return null;
};
