<template>
  <b-card no-body class="mb-0">
    <b-row class="m-2 pt-2">
      <b-col cols="12" md="3">
        <div class="d-flex flex-column align-items-center">
          <b-avatar
            rounded="bottom"
            variant="light-dark"
            size="168"
            :src="constructUrlImage(userData.avatar)"
          />
          <b-button
            variant="primary"
            type="submit"
            class="mt-1"
            @click="$refs.refInputEl.click()"
          >
            <input
              ref="refInputEl"
              type="file"
              class="d-none"
              @input="inputImage"
            />
            {{ $t("upload_image") }}
          </b-button>
        </div>
      </b-col>
      <b-col cols="12" md="8">
        <validation-observer #default="{ handleSubmit }" ref="refFormObserver">
          <b-form
            @submit.prevent="handleSubmit(onSubmit)"
            @reset.prevent="resetForm"
          >
            <!-- {{ $t('first_name') }} -->
            <validation-provider
              #default="validationContext"
              :name="$t('first_name')"
              rules="required"
            >
              <b-form-group :label="$t('first_name')" label-for="first-name">
                <b-form-input
                  id="first-name"
                  v-model="userData.firstName"
                  :state="getValidationState(validationContext)"
                  trim
                />

                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>

            <!-- Last name -->
            <validation-provider
              #default="validationContext"
              :name="$t('last_name')"
              rules="required"
            >
              <b-form-group :label="$t('last_name')" label-for="last-name">
                <b-form-input
                  id="last-name"
                  v-model="userData.lastName"
                  :state="getValidationState(validationContext)"
                  trim
                />

                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>

            <!-- Email -->
            <b-form-group :label="$t('email')" label-for="email">
              <b-form-input id="email" v-model="userData.email" disabled />
            </b-form-group>

            <!-- Username -->
            <b-form-group :label="$t('username')" label-for="username">
              <b-form-input id="username" v-model="userData.username" />
            </b-form-group>

            <!-- Form Actions -->
            <div class="d-flex mt-2">
              <b-button variant="primary" class="mr-2" type="submit">
                {{ $t("save") }}
              </b-button>
              <b-button type="button" variant="outline-secondary">
                {{ $t("cancel") }}
              </b-button>
            </div>
          </b-form>
        </validation-observer>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import {
  BFormFile,
  BButton,
  BForm,
  BFormGroup,
  BFormInput,
  BRow,
  BCol,
  BAlert,
  BCard,
  BCardText,
  BMedia,
  BMediaAside,
  BMediaBody,
  BLink,
  BFormInvalidFeedback,
  BFormTextarea,
  BAvatar,
  BImg,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import { ref } from "@vue/composition-api";
import formValidation from "@core/comp-functions/forms/form-validation";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { useToast } from "vue-toastification/composition";
import { required, integer, email } from "@validations";
import { adminService } from "@/services/admin";
import { uploadService } from "@/services/upload";
import { constructUrlImage } from "@/libs/helper";
export default {
  components: {
    BButton,
    BForm,
    BImg,
    BFormFile,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BAlert,
    BCard,
    BCardText,
    BMedia,
    BAvatar,
    BMediaAside,
    BMediaBody,
    BFormInvalidFeedback,
    BFormTextarea,
    BLink,
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  props: {
    userDataProps: {
      type: Object,
      default: () => {},
    },
    loading: {
      type: Boolean,
      default: () => false,
    },
  },
  setup(props) {
    const toast = useToast();
    const loading = ref(false);
    const previewLogo = ref(null);
    const uploadCommercialFile = ref(null);
    const logoFile = ref(null);

    const userData = ref({ ...props.userDataProps });

    const blankUserData = {
      companyName: "",
      address: "",
      mobileNo: "",
      logo: "",
      email: "",
      username: "",
      password: "",
    };

    const resetUserData = () => {
      userData.value = { ...blankUserData };
    };
    const { refFormObserver, getValidationState, resetForm } =
      formValidation(resetUserData);

    const inputImage = (e) => {
      logoFile.value = e.target.files[0];
      previewLogo.value = URL.createObjectURL(logoFile.value);
    };

    const onSubmit = async () => {
      const user = localStorage.getItem("userData");
      loading.value = true;
      const updateData = { ...userData.value };

      delete updateData.avatar;
      try {
        //check file if file not null then call api to store file and get file to userData
        if (logoFile.value) {
          const formDataLogo = new FormData();
          formDataLogo.append("file", logoFile.value, logoFile.value.name);
          formDataLogo.append("fileType", "avatar");

          const respLogoFile = await uploadService.uploadFile(formDataLogo);
          updateData.avatar = respLogoFile.data._id;
        }

        let response = await adminService.updateData(updateData, user.id);

        if (response.status) {
          loading.value = false;
          toast({
            component: ToastificationContent,
            props: {
              title: "จัดการโปรไฟล์สำเร็จ",
              icon: "CheckIcon",
              variant: "success",
            },
          });
        }
      } catch (error) {
        console.log(error);
        loading.value = false;
        toast({
          component: ToastificationContent,
          props: {
            title: "จัดการโปรไฟล์ไม่สำเร็จ",
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
      }
    };

    return {
      inputImage,
      previewLogo,

      required,
      integer,
      email,

      getValidationState,
      refFormObserver,
      uploadCommercialFile,

      onSubmit,
      userData,

      constructUrlImage,
    };
  },
};
</script>
